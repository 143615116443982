import React, { useEffect, useState } from "react";
import Twitter from "./components/Twitter";
import logoSvg from "./images/upwardTrail.svg";

import "./css/app.css";

function App() {
  const [isAnimating, setIsAnimating] = useState(false);
  useEffect(() => {
    setIsAnimating(true);
  }, []);
  return (
    <div className="App">
      <div className="mainContent">
        <div
          className={`anim-logo anim-delay-1 ${
            isAnimating ? "anim-active" : ""
          }`}
        >
          <div className="logoWrap">
            <img src={logoSvg} className="logoSvg" alt="Upward Trail" />
          </div>
        </div>
        <div
          className={`anim-text anim-delay-2 ${
            isAnimating ? "anim-active" : ""
          }`}
        >
          <div className="explainer">
            <div className="inner">
              Plan, log, and share your outdoor experiences on UpwardTrail.com.
              Find the best gear for your trek, plan your route, and learn from
              other outdoorsy folks. Whether you're a hiker, biker, paddler or
              camper, adventure awaits you on UpwardTrail.com.
            </div>
          </div>
        </div>
        <div
          className={`anim-default anim-delay-3 ${
            isAnimating ? "anim-active" : ""
          }`}
        >
          <Twitter />
        </div>
        <div
          className={`anim-default anim-delay-4 ${
            isAnimating ? "anim-active" : ""
          }`}
        >
          <h2>Coming soon.</h2>
        </div>
      </div>
    </div>
  );
}

export default App;
